import { useState, useContext } from 'react';

import { paperAirplane } from 'site-react/assets';
import { Button } from 'site-react/components/form';
import { ModalNewContext } from 'site-react/components/page/ModalNew';
import { Heading } from 'site-react/components/typography';
import { Centered, VerticalSpacing } from 'site-react/components/utility';

import Form from './components/Form';

const ModalPage = {
  Form: 'form',
  Success: 'success',
};

export default function AdvisorCreateRecommendationListFormModal({
  onSuccess = () => {},
}) {
  const { closeModal } = useContext(ModalNewContext);
  const [modalPage, setModalPage] = useState(ModalPage.Form);

  if (modalPage === ModalPage.Form) {
    return (
      <>
        <Heading level="1" type="title1">
          Create a new list
        </Heading>
        <VerticalSpacing size="md" />
        <Form
          onSuccess={(newRecList) => {
            setModalPage(ModalPage.Success);
            onSuccess(newRecList);
          }}
        />
      </>
    );
  }
  if (modalPage === ModalPage.Success) {
    return (
      <Centered>
        <img alt="" src={paperAirplane} />

        <VerticalSpacing size="lg" />

        <Heading level="1" type="title1">
          Your recommendation list has been created
        </Heading>

        <VerticalSpacing size="lg" />

        <Button
          name="Back to Recommendations"
          onClick={closeModal}
          styleType="secondary"
          type="button"
        >
          Back to Recommendations
        </Button>
      </Centered>
    );
  }
}
