import React, { useEffect, useState } from 'react';

import { formatPrice } from 'site-react/components/typography/Price';
import config from 'site-react/config';
import {
  useGoogleMapsScript,
  useMap,
  useMapApi,
  SearchNanoCard,
} from 'site-react/features/Map';

import styles from './RecommendationMap.module.css';

const RecommendationMap = ({
  onMarkerClick = () => {},
  onMarkerMouseEnter = () => {},
  onMarkerMouseLeave = () => {},
  recommendationList,
  selectedRecListItemId = null,
}) => {
  const [openMarkerId, setOpenMarkerId] = useState();
  const [markers, setMarkers] = useState({});
  const [isScriptReady, setIsScriptReady] = useState(false);
  const { GoogleMap, mapRef } = useMapApi(isScriptReady);
  const element = mapRef.current;
  useGoogleMapsScript(setIsScriptReady);

  const { map } = useMap({
    element,
    GoogleMap,
    mapId: config.GOOGLE_MAP_RECOMMENDATION_MAP_ID,
  });

  useEffect(() => {
    // whenever the parent component gives us a new selected list item
    // highlight the associated marker
    if (markers) {
      for (const key in markers) {
        const marker = markers[key];
        // lazy way of removing the selectedStyle from whatever was selected before
        marker.content.classList.remove(
          styles['RecommendationMap-marker--selected'],
        );
      }
      const selectedMarker = markers[selectedRecListItemId];
      if (selectedMarker && selectedRecListItemId) {
        markers[selectedRecListItemId].content.classList.add(
          styles['RecommendationMap-marker--selected'],
        );
      }
    }
  }, [selectedRecListItemId, markers]);

  useEffect(() => {
    const getAdvancedMarkerElement = async () => {
      const { AdvancedMarkerElement } = await GoogleMap.importLibrary('marker');
      return AdvancedMarkerElement;
    };

    const addMarker = async (AdvancedMarkerElement, recListItem) => {
      const bounds = new GoogleMap.LatLngBounds();
      const markerContent = document.createElement('div');
      if (recListItem.isPartTime) {
        // TODO: this isn't formatted as currency because we use this price to calculate the total price for selected days
        const formattedPrice = formatPrice(
          parseInt(recListItem.pricePlan.partTimePeakPrice, 10),
        );
        markerContent.textContent = formattedPrice;
      } else {
        // this is already formatted as a currency
        markerContent.textContent = formatPrice(
          parseInt(recListItem.pricePlan.fullTimeMonthlyPrice, 10),
        );
      }
      markerContent.className = styles['RecommendationMap-marker'];
      const marker = new AdvancedMarkerElement({
        content: markerContent,
        map,
        position: {
          lat: recListItem.building.lat,
          lng: recListItem.building.lng,
        },
      });

      bounds.extend(
        new GoogleMap.LatLng(
          recListItem.building.lat,
          recListItem.building.lng,
        ),
      );

      // this is a bit of google jank, you need a click event
      // to enable the hover events
      marker.addListener('click', () => {
        onMarkerClick(recListItem);
        map.panTo(
          new GoogleMap.LatLng(
            recListItem.building.lat,
            recListItem.building.lng,
          ),
        );
        const closeMarker = () => {
          setOpenMarkerId();
        };
        GoogleMap.event.addListenerOnce(map, 'idle', () => {
          setOpenMarkerId(recListItem.id);
          GoogleMap.event.addListenerOnce(map, 'click', closeMarker);
          GoogleMap.event.addListenerOnce(map, 'bounds_changed', closeMarker);
        });
      });

      marker.content.addEventListener('mouseenter', () => {
        // when this marker is hovered over tell the parent
        // used so the parent can update the selected item
        onMarkerMouseEnter(recListItem);
      });

      marker.content.addEventListener('mouseleave', () => {
        onMarkerMouseLeave(null);
      });
      setMarkers((markers) => {
        // can't set a property using the dot syntax if
        // the key is a variable so need to create a temp object
        const tmpMarkers = { ...markers };
        tmpMarkers[recListItem.id] = marker;
        return tmpMarkers;
      });

      if (map) {
        map.fitBounds(bounds);
      }
    };

    const updateMarkers = async () => {
      const AdvancedMarkerElement = await getAdvancedMarkerElement();
      recommendationList.items.forEach((item) => {
        if (!markers[item.id]) {
          addMarker(AdvancedMarkerElement, item);
        }
      });

      // delete any markers for items that don't exist
      const itemIds = recommendationList.items.map((item) =>
        item.id.toString(),
      );
      for (const markerId in markers) {
        if (!itemIds.includes(markerId.toString())) {
          markers[markerId].map = null;
          delete markers[markerId];
        }
      }
    };

    if (GoogleMap && map) {
      updateMarkers();
    }
  }, [
    GoogleMap,
    map,
    markers,
    onMarkerClick,
    onMarkerMouseEnter,
    onMarkerMouseLeave,
    recommendationList,
  ]);

  return (
    <>
      <div className={styles['RecommendationMap']} ref={mapRef} />
      {recommendationList.items.map((item) => {
        if (openMarkerId === item.id) {
          // fullTimeMonthlyPrice is NaN
          const itemPrice = parseInt(
            item.isPartTime
              ? item.pricePlan.partTimeOffPeakPrice
              : item.pricePlan.fullTimeMonthlyPrice,
          );
          return (
            <div className={styles['RecommendationMap-nanoCard']} key={item.id}>
              <SearchNanoCard
                alt={`${item.building.name} main image`}
                buildingId={item.building.id}
                href={`/office-space/${item.building.id}/${item.building.slug}`}
                isPartTime={item.isPartTime}
                price={itemPrice}
                pricePlanId={item.id}
                src={item.building.images[0].img}
                title={item.building.name}
              />
            </div>
          );
        }
        return <div key={item.id} />;
      })}
    </>
  );
};

export default RecommendationMap;
