import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import { Badges, Heading, Price } from 'site-react/components/typography';
import config from 'site-react/config';
import { OfficeAccessOptions } from 'site-react/data/listing/ViewingRequestContext';
import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import styles from './SearchNanoCard.module.css';

const imageSize = 96;

const SearchNanoCard = ({
  alt = '',
  analyticsMetadata = {},
  href: hrefProp,
  isPartTime,
  officeCount,
  onLinkClick = () => {},
  price,
  labels = [],
  src,
  title,
}) => {
  const onClick = (...args) => {
    analytics.track('Nano card clicked', analyticsMetadata, {
      sendPageProperties: true,
    });
    onLinkClick(...args);
  };

  let href;

  if (isPartTime) {
    const url = new URL(hrefProp, config.HUBBLE_URL);
    url.searchParams.set('access', OfficeAccessOptions.PartTime);
    href = url.toString();
  } else {
    href = hrefProp;
  }

  return (
    <NextLink
      className={styles['SearchNanoCard']}
      href={href}
      onClick={onClick}
      title={title}
    >
      <div className={styles['SearchNanoCard-imgWrapper']}>
        <img
          alt={alt}
          className={styles['SearchNanoCard-img']}
          src={`${src}?q=30&h=${imageSize * 2}&w=${imageSize * 2}`}
          srcSet={`${src}?q=30&h=${imageSize * 2}&w=${
            imageSize * 2
          }, ${src}?dpr=2&h=${imageSize * 2}&q=30&w=${imageSize * 2} 2x`}
        />
      </div>
      <div className={styles['SearchNanoCard-info']}>
        <div className={styles['SearchNanoCard-header']}>
          <Heading level="2" type="title4">
            <div className={styles['SearchNanoCard-title']}>{title}</div>
          </Heading>
          <dl className={styles['SearchNanoCard-price']}>
            <dt>
              {officeCount} office{officeCount > 1 ? 's' : ''}
            </dt>
            <dd className={styles['SearchNanoCard-priceInfoDetail']}>
              From <Price amount={price} />
              /m
            </dd>
          </dl>
        </div>
        <div className={styles['SearchNanoCard-badges']}>
          <Badges badges={labels} isSmallVariant />
        </div>
      </div>
    </NextLink>
  );
};

SearchNanoCard.propTypes = {
  /**
   * The alt text for this image
   */
  alt: PropTypes.string,

  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * The path or URL that this card links to
   */
  href: PropTypes.string.isRequired,

  /**
   * Is part time filter selected
   */
  isPartTime: PropTypes.bool,

  /**
   * The array of label objects
   */
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(['brandPrimary', 'white']),
      desc: PropTypes.string,
      isBorderVariant: PropTypes.bool,
      label: PropTypes.string,
    }),
  ),

  /**
   * The number of offices available
   */
  officeCount: PropTypes.number,

  /**
   * Callback when link is clicked
   */
  onLinkClick: PropTypes.func,

  /**
   * The price to show.
   */
  price: PropTypes.number,

  /**
   * The URL for this image
   */
  src: PropTypes.string.isRequired,

  /**
   * Primary title to show on this card
   */
  title: PropTypes.string.isRequired,
};

export default SearchNanoCard;
