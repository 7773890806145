import HubbleBaseApi from 'site-react/api/HubbleBaseApi';

class SpaciousApi extends HubbleBaseApi {
  /**
   * Attempt to confirm a user's email address.
   * On success the logged-in user is marked as verified.
   */
  async verify(token) {
    return this.post(`users/verify/${token}`);
  }

  /**
   * Generate new ical token
   */
  async generateNewIcalToken() {
    return this.post('users/generate-new-ical-token');
  }

  /**
   * Create a pending email verification for the user.
   */
  async createVerify() {
    return this.post('users/verify');
  }

  /**
   * Check if spacious database is up
   */
  async getUptimeStatus() {
    return this.get('uptime/spacious/');
  }

  /**
   * Soft deletes a user's account
   */
  async deleteUser() {
    return this.delete('users/delete/');
  }

  /**
   * Create billing information
   *
   * @param {object} payload the payload to send.
   */
  async createBillingInformation(organisationId, payload) {
    return this.post(
      `organisation/${organisationId}/billing-information/`,
      payload,
    );
  }

  /**
   * Update billing information
   *
   * @param {object} payload the payload to send.
   */
  async updateBillingInformation(
    organisationId,
    billingInformationId,
    payload,
  ) {
    return this.put(
      `organisation/${organisationId}/billing-information/${billingInformationId}`,
      payload,
    );
  }

  /**
   * Shareable shortlists
   *
   */
  async getShortlistShareToken() {
    return this.get(`/user/favourites/share`);
  }

  async getShareableShortlistsFromUserToken(token) {
    return await this.get(`user/favourites/share/${token}`);
  }

  /**
   * Quotes
   */
  async createQuoteFullTime(payload) {
    return this.post('quotes/full-time/', payload);
  }

  async createQuotePartTime(payload) {
    return this.post('quotes/part-time/', payload);
  }

  /**
   * Recommendations
   */
  async createRecommendationsRequest(payload) {
    return this.post('recommendations/request/', payload);
  }

  async getAdminRecommendationList(id) {
    return this.get(`admin/recommendations/${id}`);
  }

  async updateAdminRecommendationList(id, payload) {
    return this.patch(`admin/recommendations/${id}`, payload);
  }

  async getAdminRecommendationLists(payload, opts) {
    return this.get('admin/recommendations', payload, opts);
  }

  async createAdminRecommendationList(payload) {
    return this.post('admin/recommendations', payload);
  }

  async createAdminRecommendationListItem(recommendationListId, payload) {
    return this.post(
      `admin/recommendations/${recommendationListId}/items`,
      payload,
    );
  }

  async deleteAdminRecommendationListItem(recommendationListId, itemId) {
    return this.delete(
      `admin/recommendations/${recommendationListId}/items/${itemId}`,
    );
  }

  async deleteAdminRecommendationList(recommendationListId) {
    return this.delete(`admin/recommendations/${recommendationListId}`);
  }

  async getRecommendationList(token) {
    return this.get(`recommendations/${token}`);
  }

  async createRecommendationsListItemReject(token, id) {
    return this.post(`recommendations/${token}/items/${id}/reject`);
  }

  async createRecommendationsListItemRestore(token, id) {
    return this.post(`recommendations/${token}/items/${id}/restore`);
  }
}

export default SpaciousApi;
