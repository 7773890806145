import PropTypes from 'prop-types';

import CalloutAdvisorContent from './components/CalloutAdvisorContent';
import CalloutGenericAdvisorContent from './components/CalloutGenericAdvisorContent';
export default function Callout({ advisor, isRecommendationListExpanded }) {
  if (advisor) {
    return (
      <CalloutAdvisorContent
        advisor={advisor}
        isRecommendationListExpanded={isRecommendationListExpanded}
      />
    );
  } else {
    return (
      <CalloutGenericAdvisorContent
        isRecommendationListExpanded={isRecommendationListExpanded}
      />
    );
  }
}

Callout.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),
};
