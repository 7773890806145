import PropTypes from 'prop-types';
import { useContext } from 'react';

import SpaciousApi from 'site-react/api/SpaciousApi';
import { Link, OutboundLink } from 'site-react/components/navigation';
import ModalNew, {
  ModalNewTemplateBasic,
} from 'site-react/components/page/ModalNew';
import {
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { ImgixImage, VerticalSpacing } from 'site-react/components/utility';
import { AlertContext } from 'site-react/data/core/AlertContext';
import ImageCarousel, {
  ImageCarouselItem,
  getSrcSet,
} from 'site-react/features/ImageCarousel';
import logError from 'site-react/helpers/logError';
import theme from 'site-react/theme';

import Actions from './components/Actions';
import styles from './TableHeader.module.css';

const imageWidths = [
  theme.breakpoints.sm,
  theme.breakpoints.md,
  theme.breakpoints.lg,
  theme.breakpoints.xl,
];

const spaciousApi = new SpaciousApi();

export default function TableHeader({
  advisor,
  recommendationListItem,
  setRecommendationList,
  token,
}) {
  const { addError } = useContext(AlertContext);

  const carouselItems = recommendationListItem.building.images.map((item) => ({
    alt: item.caption,
    id: item.id,
    sizes: `(min-width: ${theme.breakpoints.md}px) 656px, 100vw`,
    src: item.img,
    srcSet: getSrcSet(item.img, imageWidths),
  }));

  async function rejectRecommendationListItem() {
    setRecommendationList((prevRecommendationList) => {
      const updatedRecommendationList = {
        ...prevRecommendationList,
        items: prevRecommendationList.items.map((item) => {
          if (item.id === recommendationListItem.id) {
            return {
              ...item,
              status: 'rejected',
            };
          }

          return item;
        }),
      };

      return updatedRecommendationList;
    });

    try {
      await spaciousApi.createRecommendationsListItemReject(
        token,
        recommendationListItem.id,
      );
    } catch (error) {
      setRecommendationList((prevRecommendationList) => {
        const revertedRecommendationList = {
          ...prevRecommendationList,
          items: prevRecommendationList.items.map((item) => {
            if (item.id === recommendationListItem.id) {
              return {
                ...item,
                status: recommendationListItem.status,
              };
            }

            return item;
          }),
        };

        return revertedRecommendationList;
      });

      logError(error);
      addError('Something went wrong. Please try again.');
    }
  }

  async function restoreRecommendationListItem() {
    setRecommendationList((prevRecommendationList) => {
      const updatedRecommendationList = {
        ...prevRecommendationList,
        items: prevRecommendationList.items.map((item) => {
          if (item.id === recommendationListItem.id) {
            return {
              ...item,
              status: 'pending',
            };
          }

          return item;
        }),
      };

      return updatedRecommendationList;
    });

    try {
      await spaciousApi.createRecommendationsListItemRestore(
        token,
        recommendationListItem.id,
      );
    } catch (error) {
      setRecommendationList((prevRecommendationList) => {
        const revertedRecommendationList = {
          ...prevRecommendationList,
          items: prevRecommendationList.items.map((item) => {
            if (item.id === recommendationListItem.id) {
              return {
                ...item,
                status: recommendationListItem.status,
              };
            }

            return item;
          }),
        };

        return revertedRecommendationList;
      });

      logError(error);
      addError('Something went wrong. Please try again.');
    }
  }

  return (
    <>
      <div className={styles['TableHeader-detail']}>
        <span className={styles['TableHeader-imgContainer']}>
          <ModalNew
            isFullScreen
            label="Building image carousel"
            renderTrigger={({ openModal }) => {
              return (
                <ImgixImage
                  alt="View building slideshow"
                  className={styles['TableHeader-img']}
                  height={80}
                  imgixOptions={{
                    dpr: 2,
                    fit: 'crop',
                    h: 80,
                    q: 30,
                    w: 80,
                  }}
                  onClick={openModal}
                  src={recommendationListItem.building.images[0].img}
                  width={80}
                />
              );
            }}
          >
            <ModalNewTemplateBasic>
              <ImageCarousel
                compact
                isFullScreen
                trackingId={recommendationListItem.building.id}
              >
                {carouselItems.map((item) => (
                  <ImageCarouselItem
                    key={`rec-carousel-item-${item.id}`}
                    {...item}
                  />
                ))}
              </ImageCarousel>
            </ModalNewTemplateBasic>
          </ModalNew>

          <span className={styles['TableHeader-imgCount']}>
            {recommendationListItem.building.images.length}
          </span>
        </span>
        <div className={styles['TableHeader-linkContainer']}>
          {recommendationListItem.advisorNotes && (
            <ModalNew
              label="Notes"
              renderTrigger={({ openModal }) => (
                <button
                  className={styles['TableHeader-notes']}
                  onClick={openModal}
                  type="button"
                >
                  Notes{' '}
                  <span className={styles['TableHeader-notesCount']}>1</span>
                </button>
              )}
            >
              <ModalNewTemplateBasic>
                <Heading level="1" type="title4">
                  A note from your Hubble advisor
                </Heading>

                <VerticalSpacing size="md" />
                {advisor && (
                  <>
                    <div className={styles['TableHeader-advisor']}>
                      <ImgixImage
                        alt="Advisor"
                        height={56}
                        src={advisor.image}
                        width={56}
                      />
                      <Heading type="title4">{advisor.name}</Heading>
                    </div>
                    <VerticalSpacing size="xs" />
                  </>
                )}

                <Paragraph isMarginless>
                  {recommendationListItem.advisorNotes}
                </Paragraph>

                {advisor && (
                  <>
                    <VerticalSpacing size="md" />
                    <OutboundLink
                      href={`mailto:${advisor.email}`}
                      styleType="secondary"
                    >
                      <TextWithIcon
                        iconColor="neutral-900"
                        iconName="mail"
                        iconPosition="left"
                        text={`Message ${advisor.name.split(' ')[0]}`}
                      />
                    </OutboundLink>
                  </>
                )}
              </ModalNewTemplateBasic>
            </ModalNew>
          )}
          <span className={styles['TableHeader-buildingName']}>
            <Link
              href={{
                pathname: `/office-space/${recommendationListItem.building.id}/${recommendationListItem.building.slug}`,
                query: {
                  [recommendationListItem.isPartTime
                    ? 'access'
                    : 'officeAccess']: recommendationListItem.isPartTime
                    ? 'partTime'
                    : 'fullTime',
                  option: recommendationListItem.pricePlan.id,
                },
              }}
              styleType="secondary"
              target="_blank"
            >
              {recommendationListItem.building.name}
            </Link>
          </span>
        </div>
      </div>
      <Actions
        handleReject={rejectRecommendationListItem}
        handleRestore={restoreRecommendationListItem}
        recommendationListItem={recommendationListItem}
        setRecommendationList={setRecommendationList}
        token={token}
      />
    </>
  );
}

TableHeader.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),
  recommendationListItem: PropTypes.object.isRequired,
  setRecommendationList: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};
