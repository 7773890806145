import cn from 'classnames';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

import { Button } from 'site-react/components/form';
import buttonStyles from 'site-react/components/styles/button.module.css';
import { TextWithIcon } from 'site-react/components/typography';

import styles from './Actions.module.css';

export default function Actions({
  handleReject,
  handleRestore,
  recommendationListItem,
}) {
  const viewingRequestPathname = `/office-space/${recommendationListItem.building.id}/${recommendationListItem.building.slug}/book-viewing`;

  if (recommendationListItem.status !== 'rejected') {
    return (
      <div className={styles.Actions}>
        <Button
          data-analytics-tag="recs_not_interested"
          data-testid="not-interested"
          isCompact
          name="Not interested"
          onClick={handleReject}
          styleType="secondary"
          type="button"
        >
          <TextWithIcon
            iconName="thumb_down"
            iconPosition="left"
            text="Not interested"
          />
        </Button>
        <NextLink
          className={cn(
            buttonStyles.Button,
            buttonStyles['Button--white'],
            buttonStyles['Button--compact'],
            styles['Actions-notInterested'],
          )}
          data-analytics-tag="recs_schedule_viewing"
          data-testid="schedule-viewing"
          href={{
            pathname: viewingRequestPathname,
            query: {
              access: recommendationListItem.isPartTime
                ? 'partTime'
                : 'fullTime',
              option: recommendationListItem.pricePlan.id,
            },
          }}
          target="_blank"
        >
          <TextWithIcon
            iconName="thumb_up"
            iconPosition="left"
            text="Schedule a viewing"
          />
        </NextLink>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          data-analytics-tag="recs_restore"
          data-testid="restore"
          isBlock
          isCompact
          name="Bring back"
          onClick={handleRestore}
          styleType="secondary"
          type="button"
        >
          <TextWithIcon
            iconName="settings_backup_restore"
            iconPosition="left"
            text="Bring back"
          />
        </Button>
      </div>
    );
  }
}

Actions.propTypes = {
  handleReject: PropTypes.func.isRequired,
  handleRestore: PropTypes.func.isRequired,
  recommendationListItem: PropTypes.shape({}).isRequired,
};
