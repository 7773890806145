export default {
  35349: {
    // Charlie - imitates Henry Yiend
    email: 'henry.yiend@hubblehq.com',
    image: '/site/advisors/henry_y.png',
    linkedin: 'https://www.linkedin.com/in/henry-yiend-6461658b/',
    name: 'Henry Yiend',
    tel: '07894758908',
  },
  53195: {
    email: 'henry.yiend@hubblehq.com',
    image: '/site/advisors/henry_y.png',
    linkedin: 'https://www.linkedin.com/in/henry-yiend-6461658b/',
    name: 'Henry Yiend',
    tel: '07894758908',
  },
  77716: {
    email: 'bessie@hubblehq.com',
    image: '/site/advisors/bessie.png',
    linkedin: 'https://www.linkedin.com/in/bessie-griffiths-480b5a227/',
    name: 'Bessie Griffiths',
    tel: '07769185162',
  },
  92870: {
    email: 'claire.saunders@hubblehq.com',
    image: '/site/advisors/claire.png',
    linkedin: 'https://www.linkedin.com/in/claire-saunders-596b77a7/',
    name: 'Claire Saunders',
    tel: '07366285685',
  },
  96669: {
    email: 'fleur.sylvester@hubblehq.com',
    image: '/site/advisors/fleur.png',
    linkedin: 'https://www.linkedin.com/in/fleur-sylvester-32b707149/',
    name: 'Fleur Sylvester',
    tel: '07816149849',
  },
  100493: {
    email: 'max.montane-willis@hubblehq.com',
    image: '/site/advisors/max.png',
    linkedin: 'https://www.linkedin.com/in/max-montané-willis-253521214/',
    name: 'Max Montane-Willis',
    tel: '07507669714',
  },
};

export const ADVISOR_GENERIC_IMAGE_SRC = '/site/advisors/generic.png';
export const ADVISOR_GENERIC_EMAIL = 'advisors@hubblehq.com';
export const ADVISOR_GENERIC_PHONE_NO = '+44 20 3608 0215';
