import advisors from 'site-react/data/advisors';

import RowBuilder from './RowBuilder';
import TableHeader from '../../components/BuildingTable/components/TableHeader';

/**
 * Formats the recommendation list items into a format that can be rendered by the BuildingTable component.
 *
 * @param {Object} recommendationList - The recommendation list to format.
 * @param {Function} setRecommendationList - This function feeds back to the page level component which
 * will update the visible recommendation list.
 */
export default function formatBuildingTableData(
  recommendationList,
  setRecommendationList,
) {
  const advisor = advisors[recommendationList.advisor];

  const columnHeaders = recommendationList.items.map((item) => (
    <TableHeader
      advisor={advisor}
      key={item.id}
      recommendationListItem={item}
      setRecommendationList={setRecommendationList}
      token={recommendationList.token}
    />
  ));

  const builder = new RowBuilder(recommendationList.items);

  let schemaType = 'full-time';

  const itemTypes = new Set();
  recommendationList.items.forEach((item) => {
    itemTypes.add(item.isPartTime ? 'part-time' : 'full-time');
  });

  if (itemTypes.has('full-time') && itemTypes.has('part-time')) {
    // if we have part time and full time item types then show the mixed view
    schemaType = 'mixed';
  } else if (itemTypes.has('part-time')) {
    // if we don't have both types check if we have part time
    // and set schema type if we do

    schemaType = 'part-time';
  } else {
    // we don't have both types
    // and we don't have part time so default to full-time
    // this isn't required because the var is initialised
    // to full-time, but it reads more nicely
    schemaType = 'full-time';
  }

  const schemas = {
    'full-time': [
      builder.getDeskRow.bind(builder),
      builder.getFullTimePriceRow.bind(builder),
      builder.getBuildingLocationRow.bind(builder),
      builder.getNearestStationRow.bind(builder),
      builder.getBuildingTypeRow.bind(builder),
      builder.getFacilitiesUtilitiesRow.bind(builder),
      builder.getFacilitiesFurnitureRow.bind(builder),
      builder.getSqftRow.bind(builder),
      builder.getSqftPerDeskRow.bind(builder),
      builder.getFullTimeTermRow.bind(builder),
      builder.getFloorRow.bind(builder),
      builder.getFacilitiesPetFriendlyRow.bind(builder),
      builder.getFacilitiesReceptionRow.bind(builder),
      builder.getFacilitiesBikeStorageRow.bind(builder),
      builder.getFacilitiesRoofTerraceRow.bind(builder),
      builder.getAvailableFromRow.bind(builder),
    ],
    mixed: [
      builder.getDeskRow.bind(builder),
      builder.getOccupancyRow.bind(builder),
      builder.getFullTimePriceRow.bind(builder),
      builder.getPartTimeMonthlyPriceRow.bind(builder),
      builder.getBuildingLocationRow.bind(builder),
      builder.getNearestStationRow.bind(builder),
      builder.getBuildingTypeRow.bind(builder),
      builder.getFacilitiesUtilitiesRow.bind(builder),
      builder.getFacilitiesFurnitureRow.bind(builder),
      builder.getSqftRow.bind(builder),
      builder.getSqftPerDeskRow.bind(builder),
      builder.getFloorRow.bind(builder),
      builder.getTermRow.bind(builder),
      builder.getAccessHoursRow.bind(builder),
      builder.getPartTimeAdditionalCostsRow.bind(builder),
      builder.getCommunalMeetingRoomsRow.bind(builder),
      builder.getPartTimeCanLeaveEquipmentRow.bind(builder),
      builder.getFacilitiesPetFriendlyRow.bind(builder),
      builder.getFacilitiesReceptionRow.bind(builder),
      builder.getFacilitiesBikeStorageRow.bind(builder),
      builder.getFacilitiesRoofTerraceRow.bind(builder),
      builder.getAvailableFromRow.bind(builder),
    ],
    'part-time': [
      builder.getDeskRow.bind(builder),
      builder.getOccupancyRow.bind(builder),
      builder.getPartTimeMonthlyPriceRow.bind(builder),
      builder.getBuildingLocationRow.bind(builder),
      builder.getNearestStationRow.bind(builder),
      builder.getBuildingTypeRow.bind(builder),
      builder.getFacilitiesUtilitiesRow.bind(builder),
      builder.getFacilitiesFurnitureRow.bind(builder),
      builder.getSqftRow.bind(builder),
      builder.getSqftPerDeskRow.bind(builder),
      builder.getFloorRow.bind(builder),
      builder.getTermRow.bind(builder),
      builder.getAccessHoursRow.bind(builder),
      builder.getPartTimeAdditionalCostsRow.bind(builder),
      builder.getCommunalMeetingRoomsRow.bind(builder),
      builder.getPartTimeCanLeaveEquipmentRow.bind(builder),
      builder.getFacilitiesPetFriendlyRow.bind(builder),
      builder.getFacilitiesReceptionRow.bind(builder),
      builder.getFacilitiesBikeStorageRow.bind(builder),
      builder.getFacilitiesRoofTerraceRow.bind(builder),
      builder.getAvailableFromRow.bind(builder),
    ],
  };
  const rowContent = schemas[schemaType].map((buildFn) => buildFn());

  return {
    columnHeaders,
    rowContent,
  };
}
